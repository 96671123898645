
const theme = {
	themeName: 'TemboSocial Admin Theme',
	typography: {
		fontFamily: [
			'Roboto', 'Arial', 'Helvetica', 'Sans-Serif', '"Segoe UI"', '"Liberation Sans"', '"Segoe UI Emoji"',
		].join(','),
	},
    palette: {
        primary: {
            main: "#86BF40",
            contrastText: "#FFFFFF",
        },
        secondary: {
            main: "#A3B0C2",
            contrastText: "#FFFFFF",
        }
    },
	overrides: {
		MuiSvgIcon: {
			fontSizeLarge: {
				fontSize: 36,
			},
		},
		MuiLinearProgress: {
			colorPrimary: {
				backgroundColor: '#1babd5',
			},
			barColorPrimary: {
				backgroundColor: '#c5eaf3',
			},
		},
		MuiCircularProgress: {
			colorPrimary:{ 
				color: '#1babd5',	
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: 0,
			},
		},
		MuiListItem: {
			root: {
				minHeight: 40,
			}
		},
		MuiMenuItem: {
			root: {
				paddingTop: 11,
				paddingBottom: 11,
				boxSizing: 'content-box',
				height: 24,
			},
			dense: {
				paddingTop: 8,
				paddingBottom: 8,
			}
		},
		MuiListItemText: {
			dense: {
				marginTop: 0,
				marginBottom: 0,
				paddingRight: 16,
			},
			multiline: {
				marginTop: 0,
				marginBottom: 0,
			},
			primary: {
				fontSize: 13,
				lineHeight: "1.5em",
			},
			secondary: {
				fontSize: "0.875rem",
				lineHeight: "1.46429em",
			},
			inset: {
				paddingLeft: 16,
				padding: "0 16px 0px 16px",
				margin: 0,
				fontSize: 13,
				"& span": {
					fontSize: 13,
				},
			}
		},
		MuiDialogTitle: {
			root: {
				padding: "24px 24px 20px",
			},
		},
		MuiDialogContent: {
			root: {
				padding: "0 24px 24px",
			},
		},
		MuiTypography: {
			body1: {
				fontSize: "0.875rem",
				lineHeight: "1.46429em",
			},
			body2: {
				fontWeight: "normal",
				lineHeight: "1.71429em",
			},
			textSecondary: {
				color: 'rgba(0, 0, 0, 0.54)',
				fontSize: 12,
			},
			subheading: {
				display: "block",
				fontSize: "1rem",
				lineHeight: "1.5em",
				fontFamily: '"Roboto",Arial,Helvetica,Sans-Serif,"Segoe UI","Liberation Sans","Segoe UI Emoji"',
			},
			caption: {
				color: 'rgb(0, 175, 219)',
				lineHeight: "1.375em",
			},
			h1: {
				fontSize: 24,
				"font-weight": 400,
				lineHeight: "1.35417em",
			},
			h2: {
				"font-size": 21,
				"font-weight": 500,
				lineHeight: "1.16667em",
			},
			h3: {
				"font-size": 15,
				"font-weight": 600,
				"vertical-align": "middle",
			},
			h5: {
				color: '#000000',
				fontSize: 11,
				fontWeight: 400,
				lineHeight: 1.6,
			},
			h6: {
				fontSize: "1.3125rem",
				fontWeight: 500,
				color: "rgba(0, 0, 0, 0.87)",
				lineHeight: "1.16667em",
			},
		},
		MuiCheckbox: {
			colorPrimary: {
				'&$checked': {
					color: '#3f51b5',
				}/*,
				'&$disabled': {
					color: '#3f51b5',
				},*/
			},
			colorSecondary: {
				'&$checked': {
					color: '#666666',
				}/*,
				'&$disabled': {
					color: '#666666',
				},*/
			},
		},
		MuiButton: {
			root: {
				fontSize: "0.8125rem",
				color: "rgba(0, 0, 0, 0.54)",
				padding: "7px 8px",
				lineHeight: "1em",
			},
			text: {
				padding: '6px 8px',
				fontWeight: 400,
			},
			contained: {
				boxShadow: "none",
				lineHeight: "1.4em",
                "&:focus": {
                    backgroundColor: "#6b993e",
                    boxShadow: "none",
                }
			},
			containedPrimary: {
				fontSize: "0.875rem",
				minHeight: 36,
				padding: "8px 16px",
			},
			containedSecondary: {
				"&:focus": {
					backgroundColor: "rgb(114, 123, 135)"
				}
			},
			outlined: {
				lineHeight: '1.4em',
				cursor: "pointer",
				minHeight: 34,
				height: 34,
				fontSize: 13,
				fontWeight: 500,
				color: "rgba(0, 0, 0, 0.87)",
				margin: 12,
				marginLeft: 0,
				backgroundColor: "transparent",
				"&:hover": {
					backgroundColor: "rgba(0, 0, 0, 0.08)",
				},
				"&:focus": {
					outline: 0,
					backgroundColor: "rgba(0, 0, 0, 0.08)",
				},
			},
			textSecondary: {
				fontSize: "0.875rem",
				fontWeight: 500,
				color: 'rgba(0, 0, 0, 0.54)',
				minHeight: 36,
				"&:hover": {
					backgroundColor: "rgba(0, 0, 0, 0.1)",
				},
				"&:focus": {
					backgroundColor: "rgba(0, 0, 0, 0.1)",
				},
			},
			textPrimary: {
				fontSize: "0.875rem",
				fontWeight: 500,
				color: '#86BF40',
				minHeight: 36,
				"&:hover, &:focus": {
					backgroundColor: "rgba(134, 191, 64, 0.2)",
				},
				'&$disabled': {
					color: 'rgba(0, 0, 0, 0.26)',
				},
			},
			raisedPrimary: {
				color: '#FFFFFF',
				background: '#86bf40',
				backgroundColor: '#86bf40',
			},
		},
		MuiFab: {
			primary: {
				backgroundColor: '#86bf40',
				'&:hover': {
					backgroundColor: 'rgb(134, 193, 53)',
				},
				'&:focus': {
					backgroundColor: 'rgb(134, 193, 53)',
				},
			}
		},
		MuiInputLabel: {
			outlined: {
				'&$marginDense': {
					transform: "translate(14px, 17.5px) scale(1)",
				}
			},
		},
		MuiOutlinedInput: {
			root: {
				'&$focused $notchedOutline': {
					borderColor: "#0a4352",
				}
			},
			inputMarginDense: {
				paddingTop: 15,
				paddingBottom: 15,
			},
		},
		MuiFilledInput: {
			root: {
				backgroundColor: 'rgba(0, 0, 0, 0.06)',
				"&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.09)",
                },
			},
			input: {
                fontSize: "0.8125rem",
            },
            underline: {
                "&:after": {
                    borderBottomColor: "#0a4352",
                },
            },
		},
        MuiFormLabel: {
            root: {
                "&$focused": {
                    color: "#0a4352",
                },
            },
		},
		MuiInputBase: {
			input: {
				height: "auto",
			},
		},
        MuiInput: {
            underline: {
                "&:after": {
                    borderBottomColor: "#0a4352",
                },
            },
        },
		MuiPickersModal: {
			dialogRoot: {
				'-ms-overflow-style': '-ms-autohiding-scrollbar',
			},
		},
		MuiDialogActions: {
			root: {
				//'justify-content': 'space-around',
			},
		},
		MuiSnackbarContent: {
			root: {
				color: '#ffffff',
				backgroundColor: '#343434',
				minWidth: '302px !important',
			},
			message: {
				overflowX: 'hidden',
			},
			variantDefault: {
			},
			variantSuccess: {
			}
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'rgba(255, 255, 255, 0.5)',
			},
		},
	},
	props: {
		MuiButton: {
			focusRipple: false,
		},
	},
    custom: {
        cancelButtonRoot: {
            "&:hover, &:focus": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
        },
        cancelButtonLabel: {
            color: "rgba(0, 0, 0, 0.54)",
            fontWeight: 500,
        }
	},
	/*
	spacing: (factor) => {
		console.log("GETTING SPACING FACTOR", factor);
		return [0, 4, 8, 16, 32, 64][factor];
	},*/
};

export default theme;
