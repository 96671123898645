(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["TemboSocialAdmin"] = factory();
	else
		root["PSLIB"] = root["PSLIB"] || {}, root["PSLIB"]["React"] = root["PSLIB"]["React"] || {}, root["PSLIB"]["React"]["Material_UI_Themes"] = root["PSLIB"]["React"]["Material_UI_Themes"] || {}, root["PSLIB"]["React"]["Material_UI_Themes"]["TemboSocialAdmin"] = factory();
})(window, function() {
return 